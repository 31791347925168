import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import {
  WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED,
  WKSPC_MOBILE_HORIZONTAL_PADDING,
} from '~/constants/WorkspaceSpacing';

export function useWorkspaceHorizontalPadding() {
  const { isAboveMediumScreen } = useBreakpointsContext();

  return useMemo(
    () => ({
      responsiveHorizontalPadding: !isAboveMediumScreen
        ? WKSPC_MOBILE_HORIZONTAL_PADDING
        : WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_CLOSED,
    }),
    [isAboveMediumScreen],
  );
}
